* {
  padding: 0;
  margin: 0;
  color: #1a1f36;
  box-sizing: border-box;
  word-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
}

:root {
  --sp: 1s; /* change speed */
  --bg: #3884ff45; /* change color */
}
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #101010;
}
h1 {
  letter-spacing: -1px;
}
a {
  color: #5469d4;
  text-decoration: unset;
}
#root {
  background: #fff;
  display: flex;
  /* width: 100%; */
  min-height: 100vh;
  overflow: hidden;
}
.loginbackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}
.flex-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.center-center {
  align-items: center;
  justify-content: center;
}
.box-root {
  box-sizing: border-box;
}
.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.loginbackground-gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [16] [left-gutter] 1fr
    [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [8] [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}
.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}
.box-background--blue {
  background-color: #5469d4;
}
.box-background--white {
  background-color: #ffffff;
}
.box-background--blue800 {
  background-color: #212d63;
}
.box-background--gray100 {
  background-color: #e3e8ee;
}
.box-background--cyan200 {
  background-color: #7fd3ed;
}
.padding-top--64 {
  padding-top: 64px;
}
.padding-top--24 {
  padding-top: 24px;
}
.padding-top--48 {
  padding-top: 48px;
}
.padding-bottom--24 {
  padding-bottom: 24px;
}
.padding-horizontal--48 {
  padding: 48px;
}
.padding-bottom--15 {
  padding-bottom: 15px;
}

.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}

.formbg {
  margin: 0px auto;
  width: 68vh;
  /* max-width: 448px; */
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
span {
  display: block;
  font-size: 20px;
  line-height: 28px;
  color: #1a1f36;
}
label {
  margin-bottom: 10px;
}
.reset-pass a,
label {
  font-size: 14px;
  font-weight: 600;
  display: block;
}
.reset-pass > a {
  text-align: right;
  margin-bottom: 10px;
}
.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}

.field input {
  font-size: 16px;
  line-height: 28px;
  padding: 8px 16px;
  width: 100%;
  min-height: 44px;
  border: unset;
  border-radius: 4px;
  outline-color: rgb(84 105 212 / 0.5);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px;
}

input[type="submit"] {
  background-color: rgb(84, 105, 212);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
    rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
}
.field-checkbox input {
  width: 20px;
  height: 15px;
  margin-right: 5px;
  box-shadow: unset;
  min-height: unset;
}
.field-checkbox label {
  display: flex;
  align-items: center;
  margin: 0;
}
a.ssolink {
  display: block;
  text-align: center;
  font-weight: 600;
}
.footer-link span {
  font-size: 14px;
  text-align: center;
}
.listing a {
  color: #697386;
  font-weight: 600;
  margin: 0 10px;
}

.animationRightLeft {
  animation: animationRightLeft 2s ease-in-out infinite;
}
.animationLeftRight {
  animation: animationLeftRight 2s ease-in-out infinite;
}
.tans3s {
  animation: animationLeftRight 3s ease-in-out infinite;
}
.tans4s {
  animation: animationLeftRight 4s ease-in-out infinite;
}

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

/* loader animation  */
.content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content div {
  width: var(--sz);
  height: var(--sz);
  box-shadow: 0 0 1vmin 0 var(--bg), -0.25vmin 0.25vmin 1vmin 0 #000b;
  background: var(--bg);
  position: absolute;
  animation: twist calc(var(--sp) * 1) ease-in-out 0s infinite alternate;
}

.content div:nth-child(1) {
  --sz: 70vmin;
  animation-delay: calc(var(--sp) * 0.07);
  filter: hue-rotate(15deg);
}
.content div:nth-child(2) {
  --sz: 65vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 2);
  filter: hue-rotate(30deg);
}
.content div:nth-child(3) {
  --sz: 60vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 3);
  filter: hue-rotate(45deg);
}
.content div:nth-child(4) {
  --sz: 55vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 4);
  filter: hue-rotate(60deg);
}
.content div:nth-child(5) {
  --sz: 50vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 5);
  filter: hue-rotate(75deg);
}
.content div:nth-child(6) {
  --sz: 45vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 6);
  filter: hue-rotate(90deg);
}
.content div:nth-child(7) {
  --sz: 40vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 7);
  filter: hue-rotate(105deg);
}
.content div:nth-child(8) {
  --sz: 35vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 8);
  filter: hue-rotate(120deg);
}
.content div:nth-child(9) {
  --sz: 30vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 9);
  filter: hue-rotate(135deg);
}
.content div:nth-child(10) {
  --sz: 25vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 10);
  filter: hue-rotate(150deg);
}
.content div:nth-child(11) {
  --sz: 20vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 11);
  filter: hue-rotate(165deg);
}
.content div:nth-child(12) {
  --sz: 15vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 12);
  filter: hue-rotate(180deg) brightness(1.5);
}
.content div:nth-child(13) {
  --sz: 10vmin;
  animation-delay: calc(calc(var(--sp) * 0.07) * 13);
  filter: hue-rotate(195deg) brightness(2);
  box-shadow: 0 0 1vmin 0 var(--bg), -0.25vmin 0.25vmin 1vmin 0 #000b,
    0 0 6vmin 0 #fffc inset;
}

@keyframes twist {
  0% {
    border-radius: 1%;
    transform: rotate(-90deg);
  }
  100% {
    border-radius: 50%;
    transform: rotate(0deg);
  }
}

.message {
  height: 1.5em;
  text-align: center;
}
.message__line {
  animation: message-fade-in-out 5s linear;
  opacity: 0;
  position: absolute;
  color: #fff;
  inset: 0;
  text-align: center;
}
.message__line:nth-child(2) {
  animation-delay: 5s;
}
.message__line:nth-child(3) {
  animation-delay: 10s;
}
.message__line:nth-child(4) {
  animation-delay: 15s;
}
.message__line:nth-child(5) {
  animation-delay: 20s;
}
.message__line:nth-child(6) {
  animation-delay: 25s;
}
.message__line:nth-child(7) {
  animation-delay: 30s;
}

.message__line:last-child {
  animation-name: message-fade-in;
  animation-delay: 35s;
  animation-fill-mode: forwards;
}
@keyframes message-fade-in {
	from { opacity: 0; }
	6%, to { opacity: 1; }
}
@keyframes message-fade-in-out {
	from, to { opacity: 0; }
	6%, 94% { opacity: 1; }
}

.checkbox-wrapper {
  --checkbox-size: 25px;
  --checkbox-color: #008cff;
  --checkbox-shadow: rgba(0, 102, 255, 0.3);
  --checkbox-border: rgba(0, 140, 255, 0.7);
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 10px;
}

.checkbox-wrapper input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-wrapper .checkmark {
  position: relative;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  border: 2px solid var(--checkbox-border);
  border-radius: 8px;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 15px var(--checkbox-shadow);
  overflow: hidden;
}

.checkbox-wrapper .checkmark::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(45deg, var(--checkbox-color), #00c3ff);
  opacity: 0;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transform: scale(0) rotate(-45deg);
}

.checkbox-wrapper input:checked ~ .checkmark::before {
  opacity: 1;
  transform: scale(1) rotate(0);
}

.checkbox-wrapper .checkmark svg {
  width: 0;
  height: 0;
  color: #1a1a1a;
  z-index: 1;
  transition: all 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  filter: drop-shadow(0 0 2px rgba(235, 230, 230, 0.5));
}

.checkbox-wrapper input:checked ~ .checkmark svg {
  width: 18px;
  height: 18px;
  transform: rotate(360deg);
}

.checkbox-wrapper:hover .checkmark {
  border-color: var(--checkbox-color);
  transform: scale(1.1);
  box-shadow:
    0 0 20px var(--checkbox-shadow),
    0 0 40px var(--checkbox-shadow),
    inset 0 0 10px var(--checkbox-shadow);
}

.checkbox-wrapper input:checked ~ .checkmark {
  animation: pulse 1s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 20px var(--checkbox-shadow);
  }
  50% {
    transform: scale(0.9);
    box-shadow:
      0 0 30px var(--checkbox-shadow),
      0 0 50px var(--checkbox-shadow);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 20px var(--checkbox-shadow);
  }
}

.checkbox-wrapper .label {
  margin-left: 15px;
  font-family: "Segoe UI", sans-serif;
  color: var(--checkbox-color);
  font-size: 18px;
  text-shadow: 0 0 10px var(--checkbox-shadow);
  opacity: 0.9;
  transition: all 0.3s;
}

.checkbox-wrapper:hover .label {
  opacity: 1;
  transform: translateX(5px);
}

.checkbox-wrapper::before {
  left: -10px;
  top: 50%;
}

.checkbox-wrapper::after {
  right: -10px;
  top: 50%;
}

.checkbox-wrapper:hover::before {
  opacity: 1;
  transform: translateX(-10px);
  box-shadow: 0 0 10px var(--checkbox-color);
}

.checkbox-wrapper:hover::after {
  opacity: 1;
  transform: translateX(10px);
  box-shadow: 0 0 10px var(--checkbox-color);
}
